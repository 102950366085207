<template>
  <div class="pad-home">
    <HeaderBar title="邀请好友" :showLeft="true"></HeaderBar>
    <div class="invite">
      <div class="invite-data">
        <div class="invite-data-item invite-data-bounty">
          <div class="invite-data-num invite-data-bounty-num">$0</div>
          <div class="invite-data-txt invite-data-bounty-txt">已获得奖励金</div>
        </div>
        <div class="invite-data-item invite-data-coupon">
          <div class="invite-data-num invite-data-coupon-num">
            <span>{{inviteData.counpt_count||0}}</span>
            <span class="simbol">张</span>
          </div>
          <div class="invite-data-txt invite-data-coupon-txt">已获得优惠券</div>
        </div>
        <div class="invite-data-item invite-data-unumber" @click.stop.prevent="goToInviteList">
          <div>
            <div class="invite-data-num invite-data-unumber-num">
              <span>{{inviteData.invitation_count||0}}</span>
              <span class="simbol">人</span>
            </div>
            <div class="invite-data-txt invite-data-unumber-txt">已邀请好友</div>
          </div>
          <div class="arrow"></div>
        </div>
      </div>
      <div class="invite-tip">
        <div class="invite-tip-title">邀好友首乘下单完成得$5打车礼包</div>
        <!-- ${{inviteData.shareit}} -->
        <div class="invite-tip-content">多邀多得，上不封顶</div>
        <div class="invite-tip-img">
          <img src="../../assets/invite_img.png" alt />
        </div>
        <div class="invite-tip-gift">好友注册即得$3新人礼包</div>
        <!-- {{inviteData.Beshared}} -->
      </div>
      <div class="invite-rule" @click.stop.prevent="popInviteRule">了解活动详细规则>></div>
      <div class="inviteBtn" @click="inviteFriend">立即邀请</div>
    </div>

    <van-popup v-model="show" :overlay="true" class="invite-rule-box">
      <div class="invite-rules">
        <div class="invite-rules-title">活动规则</div>
        <div class="invite-rules-list">
          <ul>
            <li>被邀人的登录页面输入邀请人的手机号码，被邀人即可获得$3优惠券；</li>
            <li>手机号及该设备从未注册过浣熊出行的用户方可领取新人礼包；</li>
            <li>被邀人完成首单并支付首单费用后，邀请人获得1张$5专车券；</li>
            <li>任何通过不正当手段获得的奖励，浣熊出行有权追回相关奖励或封停账号。</li>
          </ul>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import HeaderBar from "@/components/headerbar/headerbar";
import { Popup, Toast } from "vant";
import { postinvite } from "@/service/";
import { getStore } from "../../lib/storage";
import wechat from "../../lib/wechat";
import { IsWx } from "../../lib/units";
import config from "../../service/httpConfig";

export default {
  name: "invite",
  components: {
    HeaderBar,
    [Popup.name]: Popup
  },
  data() {
    return {
      show: false,
      inviteData: ""
    };
  },
  methods: {
    goToInviteList() {
      this.$router.push("/inviteList");
    },
    popInviteRule() {
      this.show = true;
    },
    async getInviteInfo(uid) {
      let res = await postinvite(uid);
      // console.log(res)
      if (res.code == 1) {
        this.inviteData = res.data;
      }
    },
    wxRegCallback() {
      this.wxShareTimeline();
      this.wxShareAppMessage();
    },
    // 分享朋友圈
    wxShareTimeline() {
      let _link = `${
        window.location.href.split("#")[0]
      }#/invitelogin/?userTel=${
        JSON.parse(getStore("userInfo")).Tel
      }&&userAreaCode=${JSON.parse(getStore("userInfo")).AreaCode.substr(
        1,
        2
      )}`;
      _link = _link.replace(window.location.search, "");
      console.log("_link;", _link);
      let option = {
        title: "浣熊分享人领优惠券活动", // 分享标题, 请自行替换
        link: _link,
        imgUrl: `${config.baseURL}/2.jpg`, // 分享图标, 请自行替换，需要绝对路径
        success: () => {
          alert("分享成功");
        },
        error: () => {
          alert("已取消分享");
        }
      };
      // console.log(option)
      // 将配置注入通用方法
      wechat.ShareTimeline(option);
    },
    // 分享好友
    wxShareAppMessage() {
      // let Tel = JSON.parse(getStore('userInfo')).Tel
      let _link = `${
        window.location.href.split("#")[0]
      }#/invitelogin/?userTel=${
        JSON.parse(getStore("userInfo")).Tel
      }&&userAreaCode=${JSON.parse(getStore("userInfo")).AreaCode.substr(
        1,
        2
      )}`;
      _link = _link.replace(window.location.search, "");
      let option = {
        title: "你的好友邀请你注册浣熊出行", // 分享标题, 请自行替换
        desc: `你的好友送给你代金券,赶紧去领`, // 分享描述, 请自行替换
        link: _link,
        imgUrl: `${config.baseURL}/2.jpg`, // 分享图标, 请自行替换，需要绝对路径
        success: () => {
          alert("分享成功");
        },
        error: () => {
          alert("已取消分享");
        }
      };
      // 将配置注入通用方法
      wechat.ShareAppMessage(option);
    },
    inviteFriend() {
      var ua = window.navigator.userAgent.toLowerCase();
      if (ua.match(/MicroMessenger/i) == "micromessenger") {
        //微信浏览器
        Toast("请点击右上角分享");
      } else {
        Toast("请分享‘我的二维码’");
      }
    }
  },
  mounted() {
    let uid = JSON.parse(getStore("userInfo")).ID;
    this.getInviteInfo(uid);

    let url = window.location.href.replace(window.location.search, "");
    wechat.wxRegister(url, this.wxRegCallback);
  }
};
</script>

<style lang="less" scoped>
@import "../../styles/mixin.less";

.pad-home {
  padding-top: 94px;
}
.invite {
  &-data {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: space-around;
    .size(690px, 280px);
    margin: 30px auto 60px;
    background: url("../../assets/invite_bg.png") 0 0 no-repeat;
    background-size: 690px 280px;
    border-radius: 20px;
    box-shadow: 0 10px 20px rgba(12, 170, 50, 0.3);
    .color(#fff);
    .text-center;

    &-item {
      width: 100%/3;
      // height: 164px;
      // margin: 60px 0;
    }

    &-coupon {
      // border-left: 4px dotted rgba(255, 255, 255, 0.5);
      // border-right: 4px dotted rgba(255, 255, 255, 0.5);
      position: relative;
      &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        display: block;
        // border-left: 2px dashed #fff;
        height: 100%;
        width: 2px;
        background: url("../../assets/dashedLine 2@2x.png") 0 0 no-repeat;
        background-size: 2px 100%;
        // border-image: linear-gradient(
        //   to bottom,
        //   rgba(255, 255, 255, 0),
        //   rgba(255, 255, 255, 0.5),
        //   rgba(255, 255, 255, 0)
        // );
        // border-image-slice: 2;
        // box-sizing: border-box;
      }
      &::after {
        content: "";
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        display: block;
        // border-left: 2px dashed #fff;
        height: 100%;
        width: 2px;
        background: url("../../assets/dashedLine 2@2x.png") 0 0 no-repeat;
        background-size: 2px 100%;
      }
    }

    &-num {
      margin-bottom: 18px;
      font-size: 50px;
      font-family: "PingFangSC-Semibold";
      font-weight: 600;
      color: rgba(255, 255, 255, 1);
      line-height: 72px;

      .simbol {
        .fontSize(24px);
        font-family: "PingFangSC-Regular";
        font-weight: 400;
      }
    }

    &-txt {
      font-size: 24px;
      font-family: "PingFangSC-Regular";
      font-weight: 400;
      color: rgba(255, 255, 255, 1);
      line-height: 34px;
    }

    &-unumber {
      position: relative;
    }
  }

  &-tip {
    text-align: center;

    &-title {
      margin-bottom: 40px;
      font-size: 40px;
      font-family: "PingFangSC-Medium";
      font-weight: 500;
      color: rgba(51, 51, 51, 1);
      line-height: 56px;
    }

    &-content {
      margin-bottom: 24px;
      font-size: 32px;
      font-family: "PingFangSC-Regular";
      font-weight: 400;
      color: rgba(51, 51, 51, 1);
      line-height: 44px;
    }

    &-img {
      .size(402px, 292px);
      margin: 0 auto;
      padding: 46px 0 0;
      text-align: center;
      img {
        width: 100%;
      }
    }
    &-gift {
      font-size: 32px;
      font-family: "PingFangSC-Regular";
      font-weight: 400;
      color: rgba(51, 51, 51, 1);
      line-height: 44px;
    }
  }

  &-rule {
    margin: 20px auto 40px;
    text-align: center;
    font-size: 24px;
    font-family: "PingFangSC-Regular";
    font-weight: 400;
    color: rgba(12, 170, 50, 1);
    line-height: 34px;
  }

  .inviteBtn {
    width: 690px;
    height: 76px;
    margin: 0 auto;
    text-align: center;
    background: rgba(12, 170, 50, 1);
    border-radius: 8px;
    font-size: 30px;
    font-family: "PingFangSC-Medium";
    font-weight: 500;
    color: rgba(255, 255, 255, 1);
    line-height: 76px;
  }

  .arrow {
    position: absolute;
    top: 50px;
    right: 20px;
    .size(10px, 20px);
    background: url("../../assets/arrow_right.png") 0 0 no-repeat;
    background-size: 10px 20px;
  }

  &-rules {
    width: 690px;
    height: 596px;
    background: rgba(255, 255, 255, 1);
    border-radius: 20px;
    padding: 60px 30px;
    box-sizing: border-box;

    &-title {
      margin: 0 auto 30px;
      text-align: center;
      font-size: 36px;
      font-family: "PingFangSC-Medium";
      font-weight: 500;
      color: rgba(34, 34, 34, 1);
      line-height: 50px;
    }

    &-list {
      font-size: 30px;
      font-family: "PingFangSC-Regular";
      font-weight: 400;
      color: rgba(51, 51, 51, 1);
      line-height: 42px;
      ul {
        li {
          margin-bottom: 20px;
          padding-inline-start: 50px;
          background: url("../../assets/rule_icon.png") 4px 10px no-repeat;
          background-size: 22px 22px;
          font-size: 30px;
          font-family: "PingFangSC-Regular";
          font-weight: 400;
          color: rgba(51, 51, 51, 1);
          line-height: 42px;
        }
      }
    }
  }
}

.invite-rule-box {
  border-radius: 20px;
}
</style>
